import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import GlobalStyle from '../assets/styles/GlobalStyle';
import { COLORS } from '../assets/styles/variables';
import Text from '../atoms/Text';
import { URLS } from '../config/constants';
import Layout, { MyContext } from '../layout/LayoutContainer';
import '../locales/i18n';
import CTABanner from '../organisms/CTABanner/CTABanner';
import CTABannerMainText from '../organisms/CTABanner/CTABannerMainText';
import Footer, { MobileFooter } from '../organisms/Footer/Footer';
import Header from '../organisms/Header';
import SectionHeader from '../organisms/SectionHeader/SectionHeader';
import SectionHeaderMainText from '../organisms/SectionHeader/SectionHeaderMainText';
import SectionHeaderSubText from '../organisms/SectionHeader/SectionHeaderSubText';
import { mobile, phone } from '../utils/media';

const OurMissionContainer = styled.div`
  background-color: ${COLORS.sectionBg};
  padding: 100px 0;

  ${mobile(css`
    padding: 30px 0;
  `)}
`;

const OurMissionWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  max-width: 1350px;
  padding: 0 30px;

  ${mobile(css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 20px;
    margin: 0 auto;
  `)}
`;

const HelpLink = styled.a`
  color: ${COLORS.link};
  text-decoration: underline;
`;

const AboutImage = styled.img`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 5px ${COLORS.shadow};
  margin: 20px 0;

  ${mobile(css`
    display: none;
  `)}
`;

const MobileAboutImage = styled(AboutImage)`
  display: none;
  ${mobile(css`
    display: block;
  `)}
`;

const CTABannerMobile = styled(CTABanner)`
  ${phone(css`
    height: 600px;
    max-height: 600px;
    min-height: 600px;
  `)}

  ${mobile(css`
    height: 600px;
    max-height: 600px;
    min-height: 350px;
  `)}
`;

class AboutPage extends React.Component {
  onSignup = () => window.open(URLS.signup, '_BLANK');
  onLogin = () => window.open(URLS.login, '_BLANK');

  render() {
    const { t } = this.props;
    const HeaderCon = (props) => {
      return <MyContext.Consumer>{(value) => <Header {...value} {...props} />}</MyContext.Consumer>;
    };

    return (
      <Layout {...this.props}>
        <HeaderCon loginCb={this.onLogin} signupCb={this.onSignup} />
        <CTABannerMobile height={500} bgImage={`about/team.png`} bgPosition={'center'}>
          <div>
            <CTABannerMainText block>{t(`app:about:cta:title`)}</CTABannerMainText>
          </div>
        </CTABannerMobile>
        <SectionHeader>
          <SectionHeaderMainText>{t('app:about:team_dedicated')}</SectionHeaderMainText>
          <SectionHeaderSubText>
            <Trans i18nKey={'app:about:team_dedicated_subtext'}>
              <Text />
              <HelpLink href={URLS.helpPortal} target={'_BLANK'} />
            </Trans>
          </SectionHeaderSubText>
        </SectionHeader>
        <OurMissionContainer>
          <OurMissionWrapper>
            <MobileAboutImage
              src={require('../assets/images/desktop/about/mission.jpg')}
              alt={t('app:about:mission')}
            />
            <div>
              <Text size={'28px'} block lineHeight={1.2}>
                {t('app:about:mission')}
              </Text>
              <br />
              <Text size={'18px'} block lineHeight={1.9}>
                <Trans i18nKey={'app:about:mission_description_p1'} />
              </Text>
              <br />
              <Text size={'18px'} block lineHeight={1.9}>
                <Trans i18nKey={'app:about:mission_description_p2'} />
              </Text>
              <br />
              <Trans i18nKey={'app:about:mission_description_p3'}>
                <HelpLink href={URLS.topconHomeUrl} target={'_BLANK'} />
              </Trans>
            </div>
            <AboutImage src={require('../assets/images/desktop/about/mission.jpg')} alt={t('app:about:mission')} />
          </OurMissionWrapper>
        </OurMissionContainer>
        <Footer />
        <MobileFooter />
        <GlobalStyle />
      </Layout>
    );
  }
}

AboutPage.propTypes = {};

export default withTranslation()(AboutPage);
