import * as PropTypes from 'prop-types';
import React from 'react';
import Text from '../../atoms/Text';
import styled, { css } from 'styled-components';
import { mobile } from '../../utils/media';

const SectionHeaderText = styled(Text)`
  ${mobile(css`
    font-size: 20px;
  `)}
`;
const SectionHeaderSubText = ({ children, bold, size, color, lineHeight, align }) => (
  <SectionHeaderText
    lineHeight={lineHeight ?? 1.8}
    block
    weight={bold && 'bold'}
    size={size ?? '20px'}
    style={{ marginTop: 20 }}
    color={color && color}
    align={align && align}
  >
    {children}
  </SectionHeaderText>
);

SectionHeaderSubText.propTypes = {
  children: PropTypes.any,
  block: PropTypes.bool,
  bold: PropTypes.bool,
};

SectionHeaderSubText.defaultProps = {
  block: false,
};

export default SectionHeaderSubText;
